import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  selector: 'ava-link',
  template: `
    @if (href) {
      <a
        [href]="href"
        [target]="target"
        tabindex="0"
        class="text-base w-full font-normal hover:text-link-active text-link focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-light {{
          class
        }}"
        (click)="clickedBtn()"
        (keydown.enter)="clickedBtn()"
      >
        {{ label }}
        <ng-container *ngTemplateOutlet="content" />
      </a>
    } @else {
      <button
        tabindex="0"
        type="button"
        class="w-full justify-center inline-flex items-center px-4 py-2 font-normal leading-6 text-base text-blue-light focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-blue-light
        {{ class }}
"
        (click)="clickedBtn()"
        (keydown.enter)="clickedBtn()"
      >
        @if (label) {
          <span>
            {{ label }}
          </span>
        }
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </button>
    }

    <ng-template #content>
      <ng-content />
    </ng-template>
  `,
  imports: [CommonModule],
  host: { class: 'inline-block' },
})
export class ButtonLinkComponent {
  @Input() class = '';
  @Input() label = '';
  @Input() href = '';
  @Input() target = '';
  @Output() clicked = new EventEmitter();

  clickedBtn() {
    this.clicked.emit();
  }
}
